export const ACTIONS = {

    SHOW_NOTIFICATION: 'notifications/show_notification'
  };
  
  interface ShowNotification {
    type: typeof ACTIONS.SHOW_NOTIFICATION;
    payload: Object;
  }

  
  
  export type Notificationtypes = |ShowNotification 
                                    